import {
	ButtonTopContainer,
	StyledArticle,
	StyledButton,
} from '../../../styles';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import {
	StyledTable,
	StyledTableContainer,
	StyledTdButtonDelete,
	StyledTdButtonPrint,
	StyledTdCounter,
	StyledTdId,
} from '../../../styles/lista';

import { Layout } from '../../../components/Layout';
import { StyledH2 } from '../../../styles/common';
import axios from '../../../config/axios';
import { useAuth } from '../../../hooks/useAuth';

import { SEO } from '../../../components/SEO';

export const Head = () => (
	<SEO 
	title='Przetargi - administracja'
	description='Administracja przetargami.'
	/>);

const isBrowser = () => typeof window !== 'undefined';

const ListaPage = () => {
	const auth = isBrowser()
		? useAuth()
		: { user: '', role: '', verifyUser: () => {}, signOut: () => {} };

	const [list, setList] = useState<any>([]);

	useEffect(() => {
		auth.verifyUser();
		const token = localStorage.getItem('token');
		if (!token) {
			navigate('/konto/logowanie/');
		}
	}, []);

	useEffect(() => {
		try {
			(async () => {
				const response = await axios.get('/get_tender_list.php');
				if (response?.status === 200) {
					const data = response.data;
					setList(data);
				}
			})();
		} catch (e) {}
	}, []);

	if (!auth.user) {
		return null;
	}

	let przetargiTrwajaceExists = false;
	let przetargiZakonczoneExists = false;
	let przetargiArchiwumExists = false;
	let sprzedazeTrwajaceExists = false;
	let sprzedazeZakonczoneExists = false;
	let sprzedazeArchiwumExists = false;

	for (let i = 0; i < list.length; i++) {
		if (list[i].status === 'przetarg trwający') przetargiTrwajaceExists = true;
		if (list[i].status === 'przetarg zakończony')
			przetargiZakonczoneExists = true;
		if (list[i].status === 'przetarg archiwum') przetargiArchiwumExists = true;
		if (list[i].status === 'sprzedaż trwająca') sprzedazeTrwajaceExists = true;
		if (list[i].status === 'sprzedaż zakończona')
			sprzedazeZakonczoneExists = true;
		if (list[i].status === 'sprzedaż archiwum') sprzedazeArchiwumExists = true;
	}

	const TableHtml = (props: any) => {
		return (
			<StyledTable>
				<caption>{props.title}</caption>
				<tbody>
					{props.tenderList.map((row: any) => {
						if (row.status === props.status) {
							return (
								<tr key={row.id}>
									<StyledTdId>
										<Link to={`/konto/przetargi/edytuj?id=${row.id}`}>
											{row.id.replace('_', '/')}
										</Link>
									</StyledTdId>
									<td>
										<Link to={`/konto/przetargi/edytuj?id=${row.id}`}>
											{row.title}
										</Link>
									</td>
									<StyledTdCounter>[&nbsp;{row.counter}&nbsp;]</StyledTdCounter>
									<StyledTdButtonPrint>
										<button
											onClick={() => {
												navigate(`/konto/przetargi/drukuj?id=${row.id}`);
											}}
										>
											drukuj
										</button>
									</StyledTdButtonPrint>
									<StyledTdButtonDelete>
										<button
											onClick={() => {
												navigate(`/konto/przetargi/usun?id=${row.id}`);
											}}
										>
											usuń
										</button>
									</StyledTdButtonDelete>
								</tr>
							);
						} else {
							return null;
						}
					})}
				</tbody>
			</StyledTable>
		);
	};

	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					Przetargi - administracja
				</StyledH2>

				<ButtonTopContainer>
					<StyledButton
						as="a"
						href="https://platformazakupowa.pl/pn/mzk_bydgoszcz"
					>
						Platforma zakupowa
					</StyledButton>
					<StyledButton as={Link} to="/konto/przetargi/dodaj" marginleft="16px">
						Dodaj przetarg
					</StyledButton>
				</ButtonTopContainer>
				<StyledTableContainer>
					{przetargiTrwajaceExists ? (
						<TableHtml
							status="przetarg trwający"
							tenderList={list}
							title="Przetargi trwające"
						/>
					) : null}
				</StyledTableContainer>
				<StyledTableContainer>
					{przetargiZakonczoneExists ? (
						<TableHtml
							status="przetarg zakończony"
							tenderList={list}
							title="Przetargi zakończone"
						/>
					) : null}
				</StyledTableContainer>
				<StyledTableContainer>
					{przetargiArchiwumExists ? (
						<TableHtml
							status="przetarg archiwum"
							tenderList={list}
							title="Przetargi archiwum"
						/>
					) : null}
				</StyledTableContainer>

				<StyledTableContainer>
					{sprzedazeTrwajaceExists ? (
						<TableHtml
							status="sprzedaż trwająca"
							tenderList={list}
							title="Sprzedaże trwające"
						/>
					) : null}
				</StyledTableContainer>
				<StyledTableContainer>
					{sprzedazeZakonczoneExists ? (
						<TableHtml
							status="sprzedaż zakończona"
							tenderList={list}
							title="Sprzedaże zakończone"
						/>
					) : null}
				</StyledTableContainer>
				<StyledTableContainer>
					{sprzedazeArchiwumExists ? (
						<TableHtml
							status="sprzedaż archiwum"
							tenderList={list}
							title="Sprzedaże archiwum"
						/>
					) : null}
				</StyledTableContainer>
			</StyledArticle>
		</Layout>
	);
};

export default ListaPage;
